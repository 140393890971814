export const darkMode = {
    init() {
        const userChoice = localStorage.theme;
        const useSystemPref = localStorage.useSystemTheme === "true";
        const systemPrefersDark = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;

        this.toggleThemeWithoutTransition(
            useSystemPref ? systemPrefersDark : userChoice === "dark"
        );
    },

    toggleThemeWithoutTransition(isDark) {
        document.documentElement.classList.toggle("dark", isDark);
    },

    startTransition() {
        document.documentElement.classList.add("theme-transitioning");

        setTimeout(() => {
            document.documentElement.classList.remove("theme-transitioning");
        }, 200);
    },

    setLight() {
        this.startTransition();
        localStorage.theme = "light";
        localStorage.removeItem("useSystemTheme");
        document.documentElement.classList.remove("dark");
    },

    setDark() {
        this.startTransition();
        localStorage.theme = "dark";
        localStorage.removeItem("useSystemTheme");
        document.documentElement.classList.add("dark");
    },

    useSystem() {
        this.startTransition();
        localStorage.removeItem("theme");
        localStorage.useSystemTheme = "true";
        this.init();
    },

    setTheme(theme) {
        if (theme === "dark") {
            this.setDark();
        } else if (theme === "light") {
            this.setLight();
        } else if (theme === "system") {
            this.useSystem();
        }
    },

    getCurrentTheme() {
        if (localStorage.useSystemTheme === "true") {
            return "system";
        }
        return localStorage.theme === "dark" ? "dark" : "light";
    },
};
